/* eslint-disable react-native/no-inline-styles */
import theme from '@/style';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {View, ScrollView} from 'react-native';
import Text from '@/components/basic/text';
import i18n from '@/i18n';
import LinearGradient from '@/components/basic/linear-gradient';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Picker from '../../../components/business/filter/picker';
import LazyImage from '@/components/basic/image';
import {sanImg} from '../proxy.variable';
import {
  HomeBettingIcon,
  HomeNewUserIcon,
  HomeRechargeIcon,
  HomeBetUserIcon,
} from '../svg.variable';
import {getTeamReportData} from '../proxy.service';
import {SafeAny} from '@/types';
import getDay from '@components/utils/getDay';
import dayjs from 'dayjs';
import {margin} from '@/components/style';
import {useFocusEffect} from '@react-navigation/native';
import {toPriceStr} from '@/utils';
import baseVariable from '@/style/base.variable';
const LinearGradientBox = {
  maxWidth: 360,
  height: 25,
};
interface getTeamReportDataObj {
  betAmount: string;
  betCommissionAmount: string;
  newUserCount: number;
  rechargeAmount: string;
  rechargeCommissionAmount: string;
  rechargeUserCount: number;
}
const ProxyTeamReportResult: React.FC = () => {
  const [date, setDate] = useState([
    {name: 'today', status: true, value: 0},
    {name: 'yesterday', status: false, value: 1},
    {name: 'threedays', status: false, value: 3},
    {name: 'sevendays', status: false, value: 7},
    {name: 'fifteendays', status: false, value: 15},
    {name: 'thirtydays', status: false, value: 30},
  ]);
  const [range, setRange] = useState<SafeAny>({});
  const [timeIndex, setTimeIndex] = useState(0);
  const pickerRef: SafeAny = useRef(null);
  const [teamReportData, setTeamReportData] = useState<getTeamReportDataObj>();
  const handleOpenPick = () => {
    pickerRef.current.handleOpen();
  };
  // dateTime
  const {startDate, endDate} = useMemo(() => {
    let dateArr: SafeAny =
      timeIndex === 10 ? range : getDay(date[timeIndex].value);
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let startDate = dateArr[0];
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let endDate = dateArr[1];
    return {startDate, endDate};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeIndex, range]);
  useFocusEffect(
    useCallback(() => {
      handleGetTeamReportData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, range]),
  );
  const handleGetTeamReportData = () => {
    getTeamReportData({startDate, endDate}).then((res: SafeAny) => {
      setTeamReportData(res);
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleConfirm = (range: SafeAny) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const startDate = dayjs(range.startDate).format('YYYYMMDD');
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const endDate = dayjs(range.endDate).format('YYYYMMDD');
    setRange([startDate, endDate]);
    setTimeIndex(10);
  };
  return (
    <View
      style={[
        theme.padding.tbxxl,
        theme.padding.lrl,
        {backgroundColor: baseVariable.basicColor.proxybgColor},
        margin.topl,
        {height: 218},
      ]}>
      <View style={[theme.flex.col, theme.flex.between]}>
        <View
          style={[theme.flex.row, theme.flex.between, theme.flex.centerByCol]}>
          <LinearGradient
            start={{x: 0, y: 0}}
            end={{x: 0, y: 1}}
            style={[
              LinearGradientBox,
              theme.flex.flex1,
              {
                borderBottomWidth: 1,
              },
              {
                borderColor: '#ffffffb3',
              },
            ]}
            colors={baseVariable.basicColor.proxyLinearGradient}>
            <ScrollView showsVerticalScrollIndicator={false} horizontal={true}>
              {date.map((item, index) => {
                return (
                  <NativeTouchableOpacity
                    onPress={() => {
                      let data = [...date];
                      data.map(val => (val.status = false));
                      data[index].status = !data[index].status;
                      setDate([...data]);
                      setTimeIndex(index);
                    }}
                    key={item.name}
                    style={[
                      theme.padding.rightm,
                      theme.flex.center,
                      {marginLeft: index === 0 ? 8 : 0},
                    ]}>
                    <div
                      className={`text-t1 font-bold ${
                        item.status ? 'text-sm' : 'text-xs'
                      }`}>
                      {i18n.t('proxy.team.' + item.name)}
                    </div>
                    <div
                      className={`w-6 h-1 mt-1 text-[rgba(0,0,0,0)] ${
                        item.status ? 'bg-[#fff]' : 'bg-[rgba(0,0,0,0)]'
                      }`}>
                      1
                    </div>
                  </NativeTouchableOpacity>
                );
              })}
            </ScrollView>
          </LinearGradient>
          <NativeTouchableOpacity onPress={handleOpenPick}>
            <div className="bg-[#fff] border border-[#D8E0EA] py-1 px-2 rounded flex flex-row items-center justify-center ml-7">
              {startDate ? (
                <View style={[{overflow: 'hidden', flexWrap: 'nowrap'}]}>
                  <Text
                    style={[{color: '#000', fontWeight: '700', fontSize: 14}]}>
                    {dayjs(startDate, 'YYYYMMDD').format('DD/MM')}
                    {startDate !== endDate
                      ? `-${dayjs(endDate, 'YYYYMMDD').format('DD/MM')}`
                      : ''}
                  </Text>
                </View>
              ) : null}
              <View style={[{marginLeft: 8}]}>
                <LazyImage
                  imageUrl={sanImg}
                  occupancy="#0000"
                  width={theme.iconSize.xs}
                  height={theme.iconSize.xs}
                />
              </View>
            </div>
          </NativeTouchableOpacity>
        </View>
        <View
          style={[
            theme.flex.flex,
            theme.flex.row,
            theme.flex.between,
            theme.padding.tbxxl,
            theme.padding.lrl,
          ]}>
          <View>
            <View
              style={[theme.flex.flex, theme.flex.row, theme.flex.centerByCol]}>
              <HomeNewUserIcon
                width={theme.iconSize.s}
                height={theme.iconSize.s}
                stroke={'#fff'}
              />
              <Text
                style={[
                  {fontSize: theme.fontSize.s, opacity: 0.7},
                  {fontWeight: '500'},
                  {color: '#fff'},
                  theme.margin.leftxxs,
                ]}>
                {i18n.t('proxy.team-report.new-user')}
              </Text>
            </View>
            <Text
              style={[
                theme.margin.topxxs,
                {fontSize: theme.fontSize.m},
                {color: '#fff'},
              ]}
              blod>
              {teamReportData?.newUserCount}
            </Text>
          </View>
          <View>
            <View
              style={[theme.flex.flex, theme.flex.row, theme.flex.centerByCol]}>
              <HomeBetUserIcon
                width={theme.iconSize.s}
                height={theme.iconSize.s}
                stroke={'#fff'}
              />
              <Text
                style={[
                  {fontSize: theme.fontSize.s, opacity: 0.7},
                  {fontWeight: '500'},
                  {color: '#fff'},
                  theme.margin.leftxxs,
                ]}>
                {i18n.t('proxy.user.recharge-user')}
              </Text>
            </View>
            <Text
              style={[
                {textAlign: 'right'},
                theme.margin.topxxs,
                {fontSize: theme.fontSize.m},
                {color: '#fff'},
              ]}
              blod>
              {teamReportData?.rechargeUserCount}
            </Text>
          </View>
        </View>
        <View
          style={[
            theme.flex.flex,
            theme.flex.row,
            theme.flex.between,
            theme.padding.lrl,
          ]}>
          <View>
            <View
              style={[theme.flex.flex, theme.flex.row, theme.flex.centerByCol]}>
              <HomeRechargeIcon
                width={theme.iconSize.s}
                height={theme.iconSize.s}
                stroke={'#fff'}
              />
              <Text
                style={[
                  {fontSize: theme.fontSize.s, opacity: 0.7},
                  {fontWeight: '500'},
                  {color: '#fff'},
                  theme.margin.leftxxs,
                ]}>
                {i18n.t('proxy.team-report.recharge')}
              </Text>
            </View>
            <Text
              style={[
                theme.margin.topxxs,
                {fontSize: theme.fontSize.m},
                {color: '#fff'},
              ]}
              blod>
              {toPriceStr(+(teamReportData?.rechargeAmount || 0))}
            </Text>
            <View
              style={[
                theme.flex.flex,
                theme.flex.row,
                theme.flex.centerByCol,
                theme.margin.tops,
              ]}>
              <Text
                style={[
                  {fontSize: theme.fontSize.s, opacity: 0.7},
                  {fontWeight: '500'},
                  {color: '#fff'},
                ]}>
                {i18n.t('proxy.team-report.recharge-commission')}
              </Text>
            </View>
            <Text
              style={[
                theme.margin.topxxs,
                {fontSize: theme.fontSize.m},
                {color: '#fff'},
              ]}
              blod>
              {toPriceStr(+(teamReportData?.rechargeCommissionAmount || 0))}
            </Text>
          </View>
          <View
            style={[{width: 1}, {backgroundColor: 'rgba(255,255,255,0.3)'}]}
          />
          <View>
            <View
              style={[theme.flex.flex, theme.flex.row, theme.flex.centerByCol]}>
              <HomeBettingIcon
                width={theme.iconSize.s}
                height={theme.iconSize.s}
                stroke={'var(--T1)'}
              />
              <Text
                style={[
                  {fontSize: theme.fontSize.s, opacity: 0.7},
                  {fontWeight: '500'},
                  {color: '#fff'},
                  theme.margin.leftxxs,
                ]}>
                {i18n.t('proxy.team-report.betting')}
              </Text>
            </View>
            <Text
              style={[
                theme.margin.topxxs,
                {fontSize: theme.fontSize.m},
                {color: '#fff'},
              ]}
              blod>
              {toPriceStr(+(teamReportData?.betAmount || 0))}
            </Text>
            <View
              style={[
                theme.flex.flex,
                theme.flex.row,
                theme.flex.centerByCol,
                theme.margin.tops,
              ]}>
              <Text
                style={[
                  {fontSize: theme.fontSize.s, opacity: 0.7},
                  {fontWeight: '500'},
                  {color: '#fff'},
                ]}>
                {i18n.t('proxy.team-report.betting-commission')}
              </Text>
            </View>
            <Text
              style={[
                theme.margin.topxxs,
                {fontSize: theme.fontSize.m},
                {color: '#fff'},
              ]}
              blod>
              {toPriceStr(+(teamReportData?.betCommissionAmount || 0))}
            </Text>
          </View>
        </View>
      </View>
      <Picker ref={pickerRef} handleConfirm={handleConfirm} />
    </View>
  );
};

export default ProxyTeamReportResult;
