import React from 'react';
import i18n from '@i18n';
import {goAgentService, goTo, toAgentApply} from '@/utils';
import {ReactComponent as AgentServiceSvg} from '../svgs/agent-service.svg';
import {ReactComponent as CommissionDetailSvg} from '../svgs/commission-detail.svg';
import {ReactComponent as InvitationRuleSvg} from '../svgs/invitation-rules.svg';
import {ReactComponent as TeamReportSvg} from '../svgs/team-report.svg';
import {ReactComponent as ArrowRoundSvg} from '@assets/svgs/arrow-round.svg';

export interface SubEntryProps {
  userId?: number;
  agentLevel?: number;
}

const SubEntry: React.FC<SubEntryProps> = ({userId, agentLevel}) => {
  const list = [
    {
      icon: <TeamReportSvg className="text-t1 size-4" />,
      title: i18n.t('proxy.home.team-report'),
    },
    {
      icon: <CommissionDetailSvg className="text-t1 size-4" />,
      title: i18n.t('proxy.home.commission-detail'),
    },
    {
      icon: <InvitationRuleSvg className="text-t1 size-4" />,
      title: i18n.t('proxy.home.invitation-rules'),
    },
    {
      icon: <AgentServiceSvg className="text-t1 size-4" />,
      title: i18n.t('proxy.home.agent-line-customer-service'),
    },
  ];
  const onPress = (index: number) => {
    const handler = [
      () => goTo('ProxyTeamReport'),
      () => {
        if (userId != null) {
          goTo('ProxyCommissionDetail', {userId, agentLevel});
        }
      },
      () => {
        toAgentApply();
      },
      () => goAgentService(),
    ];
    handler[index]?.();
  };
  return (
    <div className="mx-3 mt-3 py-3 px-5 bg-card flex flex-col rounded-lg">
      {list.map((item, index) => (
        <div
          key={index}
          onClick={() => onPress(index)}
          className="flex flex-row h-10 items-center justify-between">
          <div className="flex flex-row items-center gap-3">
            {item.icon}
            <span className="text-sm text-t1">{item.title}</span>
          </div>
          <ArrowRoundSvg className="text-t3 size-4 -rotate-90" />
        </div>
      ))}
    </div>
  );
};

export default SubEntry;
