/* eslint-disable react-native/no-inline-styles */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {View, ScrollView, StyleSheet} from 'react-native';
import DetailNavTitle from '@/components/business/detail-nav-title';
import {copyText, goBack, goTo, useResponsiveDimensions} from '@/utils';
import Modal from './modal';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@/services/global.state';
import {getVipRender} from '@businessComponents/vip';
import cls from './pofile.module.scss';
import {VipRenderType} from '@businessComponents/vip';

import {
  uploadProfilePhotoBase64,
  updateProfile,
  getDefaultAvatar,
} from './pofile.service';
import {postUserInfo} from '@/services/global.service';
import i18n from '@i18n';
import Upload from './upload';
import BoxShadow from '@/components/basic/shadow';

import {
  flex,
  margin,
  font,
  background,
  position,
  overflow,
  fontColor,
  basicColor,
} from '@/components/style';
import LazyImage from '@/components/basic/image';
import Text from '@basicComponents/text';

import {
  pofileImgWidth,
  defaultHeaderImg,
  pofileMagin,
  RectangleImgWidth,
  pofilePadding,
  rightIcon,
  pic,
  padding20,
  borderRadius50,
} from './profile.variable';
const edit = require('@components/assets/pofile/edit.07.svg').default;

import {SmartPhone, IDIcon, LockIcon, AddImg, CopyImg} from './svg.variable';
import Spin from '@/components/basic/spin';
import * as baseVariable from '@components/style/base.variable';
import {showToast} from '@/utils/toast';
const borderBox = {
  borderColor: basicColor.primary,
};
const PersonalCenter = () => {
  const [info, setInfo] = useState<any>({});
  const [avatar, setAvatar] = useState<any>([]);

  useEffect(() => {
    getDefaultAvatar().then(res => setAvatar(res));
    getUserInfo();
  }, []);
  const getUserInfo = async () => {
    const res: any = await postUserInfo();
    setInfo(res);
    return res;
  };
  const currentVipOption: VipRenderType = useMemo(
    () => getVipRender(info.level ? info.level : 0),
    [info.level],
  );

  const editRef: any = useRef(null);

  const imagePicker: any =
    globalStore.isAndroid && require('react-native-image-picker');

  // 从相册中选择
  const changeAvatar = async () => {
    imagePicker.launchImageLibrary(
      {
        mediaType: 'photo',
        maxWidth: 1000, // 设置选择照片的大小，设置小的话会相应的进行压缩
        maxHeight: 1000,
        quality: 0.8,
        includeBase64: true,
      },
      async (res: any) => {
        if (res.didCancel) {
          return false;
        }
        if (globalStore.isAndroid) {
          // 对获取的图片进行处理
          const ret: any = await uploadProfilePhotoBase64({
            data: 'data:image/png;base64,' + res?.assets[0]?.base64,
          });
          await updateProfile(info?.userName, ret);
          getUserInfo();
        }
      },
    );
  };
  const handleChangeAvatar = async (img: any) => {
    await updateProfile(info?.userName, img);
    getUserInfo();
  };

  const handleCopy = () => {
    if (info?.userId) {
      copyText('' + (info?.userId + 100000));
      showToast('success', i18n.t('share.copy-success'));
    }
  };

  const {height: screenHeight} = useResponsiveDimensions();
  const [loading, setLoading] = useState(false);
  return (
    <View style={[background.lightGrey]}>
      <DetailNavTitle
        containerStyle={{backgroundColor: 'transparent'}}
        title={'My Profile'}
        hideServer={true}
        hideAmount={true}
        onBack={() => goBack()}
      />
      <Spin
        loading={loading}
        style={[
          {
            height: screenHeight,
            backgroundColor: baseVariable.backgroundColor.lightGrey,
          },
        ]}>
        <div className={cls.user}>
          <div
            className={cls.userBlur}
            style={{
              backgroundImage: `url(${
                info?.userAvatar ? info?.userAvatar : defaultHeaderImg
              })`,
            }}
          />
          <View
            style={[
              flex.row,
              margin.btmm,
              flex.center,
              flex.centerByCol,
              pofileMagin,
              position.rel,
              borderRadius50,
              overflow.hidden,
            ]}>
            <LazyImage
              occupancy={'transparent'}
              resizeMode="cover"
              imageUrl={info?.userAvatar ? info?.userAvatar : defaultHeaderImg}
              width={pofileImgWidth}
              height={pofileImgWidth}
              radius={pofileImgWidth}
            />
            <View style={[position.abs]}>
              {globalStore.isAndroid ? (
                <NativeTouchableOpacity onPress={() => changeAvatar()}>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={pic}
                    width={32}
                    height={32}
                  />
                </NativeTouchableOpacity>
              ) : (
                <Upload
                  pic={
                    <LazyImage
                      occupancy={'transparent'}
                      imageUrl={pic}
                      width={48}
                      height={48}
                    />
                  }
                  getUserInfo={getUserInfo}
                  startUpload={() => setLoading(true)}
                  endUpload={() => setLoading(false)}
                />
              )}
            </View>
          </View>
          <View
            style={[
              pofilePadding,
              flex.flex,
              flex.centerByCol,
              flex.row,
              flex.center,
            ]}>
            <ScrollView
              showsVerticalScrollIndicator={false}
              horizontal={true}
              style={{width: 248}}>
              {avatar.map((item: any, index: number) => {
                return (
                  <View
                    key={index}
                    style={
                      info?.userAvatar === item
                        ? [
                            margin.rights,
                            borderBox,
                            {borderRadius: 50, borderWidth: 2},
                          ]
                        : [
                            margin.rights,
                            {borderWidth: 2, borderColor: 'transparent'},
                          ]
                    }>
                    <NativeTouchableOpacity
                      onPress={() => handleChangeAvatar(item)}>
                      <LazyImage
                        occupancy={'transparent'}
                        imageUrl={item}
                        width={RectangleImgWidth}
                        height={RectangleImgWidth}
                        radius={50}
                      />
                    </NativeTouchableOpacity>
                  </View>
                );
              })}
            </ScrollView>
            {globalStore.isAndroid ? (
              <NativeTouchableOpacity onPress={() => changeAvatar()}>
                <View
                  style={[
                    margin.leftl,
                    {
                      width: 48,
                      height: 48,
                      backgroundColor: '#fff',
                      borderRadius: 50,
                    },
                    flex.center,
                  ]}>
                  <AddImg height={16} radius={50} fill={basicColor.primary} />
                </View>
              </NativeTouchableOpacity>
            ) : (
              <View style={[margin.leftl]}>
                <BoxShadow
                  shadowStyle={{
                    radius: 50,
                    out: {x: 0, y: 4, blur: 4, color: 'rgba(0, 0, 0, 0.10)'},
                  }}>
                  <View
                    style={[
                      {
                        width: 48,
                        height: 48,
                        backgroundColor: '#fff',
                        borderRadius: 50,
                      },
                      flex.center,
                    ]}>
                    <Upload
                      pic={
                        <AddImg
                          height={16}
                          radius={50}
                          fill={basicColor.primary}
                        />
                      }
                      getUserInfo={getUserInfo}
                      startUpload={() => setLoading(true)}
                      endUpload={() => setLoading(false)}
                    />
                  </View>
                </BoxShadow>
              </View>
            )}
          </View>
          <View style={[flex.flex, flex.center, flex.row, pofileMagin]}>
            {currentVipOption && currentVipOption.smallFn(20)}
            <Text
              style={[
                margin.leftxxs,
                margin.rights,
                font.second,
                font.fontInterBold,
                font.fm,
              ]}>
              {info?.userName}
            </Text>
            <NativeTouchableOpacity
              onPress={() => {
                editRef.current.show();
                editRef.current.handleSetName(info?.userName);
              }}>
              <LazyImage
                occupancy={'transparent'}
                imageUrl={edit}
                width={24}
                height={24}
              />
            </NativeTouchableOpacity>
          </View>
        </div>
        <View style={[{width: '100%', paddingHorizontal: 12}]}>
          <View style={styles.card}>
            <View
              style={[
                padding20,
                flex.flex,
                flex.row,
                flex.between,
                flex.centerByCol,
                {height: 48},
              ]}>
              <View style={[flex.flex, flex.centerByCol, flex.row]}>
                <SmartPhone width={16} height={16} />
                <Text style={[margin.l, font.second, font.fm, font.accent]}>
                  Phone number
                </Text>
              </View>
              <Text color={fontColor.second} style={[font.second, font.fm]}>
                {info?.userPhone}
              </Text>
            </View>
            <View
              style={[
                padding20,
                flex.flex,
                flex.row,
                flex.between,
                flex.centerByCol,
                {height: 48},
              ]}>
              <View style={[flex.flex, flex.centerByCol, flex.row]}>
                <LockIcon width={16} height={16} />
                <Text style={[margin.l, font.second, font.fm, font.accent]}>
                  Change password
                </Text>
              </View>
              <NativeTouchableOpacity onPress={() => goTo('SetPassword')}>
                <View
                  style={[flex.flex, flex.centerByCol, flex.row, flex.center]}>
                  <Text
                    color={fontColor.second}
                    style={[margin.leftxxs, margin.l, font.second, font.fm]}>
                    ******
                  </Text>
                  <LazyImage
                    occupancy={'transparent'}
                    imageUrl={rightIcon}
                    width={14}
                    height={14}
                  />
                </View>
              </NativeTouchableOpacity>
            </View>
            <View
              style={[
                padding20,
                flex.flex,
                flex.row,
                flex.between,
                flex.centerByCol,
                {height: 48},
              ]}>
              <View style={[flex.flex, flex.centerByCol, flex.row]}>
                <IDIcon width={16} height={16} />
                <Text style={[margin.l, font.second, font.fm, font.accent]}>
                  User ID
                </Text>
              </View>
              <View
                style={[flex.flex, flex.centerByCol, flex.row, flex.center]}>
                <Text
                  color={fontColor.second}
                  style={[margin.leftxxs, margin.l, font.second, font.fm]}>
                  {+(info?.userId || 0) + 100000}
                </Text>
                <NativeTouchableOpacity onPress={handleCopy}>
                  <CopyImg width={16} height={16} />
                </NativeTouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Spin>
      <Modal ref={editRef} getUserInfo={getUserInfo} />
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'var(--card)',
    paddingHorizontal: 12,
    borderRadius: 10,
    width: '100%',
  },
});
export default PersonalCenter;
