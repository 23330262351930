import React, {useRef, useState, useEffect} from 'react';
import {NoMoreData} from '@/components/basic/default-page';
import {PromotionListItem, getPromotionList} from './promotion.service';
import globalStore from '@/services/global.state';
import NoData from '@/components/basic/error-pages/no-data';
import {goTo, goWhatsAppChat} from '@/utils';
import {useTranslation} from 'react-i18next';
import {goToUrl} from '@/common-pages/game-navigate';
import {Image} from 'antd-mobile';
import Spin from '@/components/v2/basic/spin';
import NavTitle from '@/components/v2/basic/nav-title';
import Balance from '@/components/v2/business/balance/balance';

const Promotion = () => {
  const {i18n} = useTranslation();

  const pageNo = useRef(1);
  const totalPage = useRef(1);

  const [promotionList, setPromotionList] = useState<PromotionListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const refreshPageInfo = (isMore = false) => {
    return getPromotionList(pageNo.current).then(pageInfo => {
      if (pageInfo?.content) {
        setPromotionList(
          isMore
            ? [...promotionList, ...pageInfo.content]
            : [...pageInfo.content],
        );
        totalPage.current = pageInfo.totalPages;
      }
    });
  };
  const refreshPage = (isMore = false) => {
    setLoading(true);
    pageNo.current = 1;
    refreshPageInfo(isMore).finally(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    refreshPage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const goPromotion = (url: string = '', title: string = '') => {
    if (url.startsWith('http')) {
      goToUrl(url, title);
    } else if (url.startsWith('gameType')) {
      const game = url.split('=')[1];
      // ToDo 无默认值这里点击按钮无效
      game && goTo(game);
    } else {
      // ToDo 无默认值这里点击按钮无效
      goWhatsAppChat(url.split(/[^\d]+/).find(s => s.length === 10));
    }
  };
  const viewDetail = (item: PromotionListItem) => {
    globalStore.asyncSetItem('promotion-detail', JSON.stringify(item));
    goTo('PromotionDetail');
  };
  return (
    <Spin loading={loading}>
      <div className="flex flex-col overflow-hidden bg-bg w-full h-screen">
        <NavTitle title={i18n.t('promotion.title')} rightNode={<Balance />} />
        <div className="flex-1 overflow-auto pt-3 px-3 pb-[3.125rem] flex flex-col gap-3 bg-bg">
          {promotionList.length ? (
            <>
              {promotionList.map(item => {
                // const content = item.activityContent
                //   ?.replace(/<[^>]+>/g, '')
                //   .trim();
                return (
                  <div
                    key={item.id}
                    className="bg-[var(--image)] rounded-lg"
                    onClick={() => {
                      if (item.activityUrl) {
                        goPromotion(item.activityUrl, item.activityTitle);
                        return;
                      }
                      if (
                        // TODO 这里不知道为什么要过滤空标签
                        // (item.activityContent?.replace(/<[^>]+>/g, '').trim() ||
                        //   item.activityContent?.startsWith('<img') ||
                        //   item.activityContent?.startsWith('<IMG'))
                        item.activityContent &&
                        item.activityContent.length > 0 &&
                        item.activityType !== 'signin'
                      ) {
                        viewDetail(item);
                        return;
                      }
                    }}>
                    <>
                      <Image
                        src={item.activityIcon}
                        width={'21.9375rem'}
                        height={'12.5rem'}
                        className="rounded-lg"
                        lazy
                      />
                    </>
                  </div>
                );
              })}
              <NoMoreData />
            </>
          ) : (
            <div className="p-4">
              <NoData />
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default Promotion;
