/* eslint-disable react-native/no-inline-styles */
import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {useModal} from '@components/basic/modal';
import {
  padding,
  flex,
  position,
  borderRadius,
  font,
  basicColor,
} from '@/components/style';
import {del} from './profile.variable';
import LazyImage from '@/components/basic/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {updateProfile} from './pofile.service';
import globalStore from '@/services/global.state';
import cls from './pofile.module.scss';

import {
  w24,
  h24,
  w295,
  fontSize18,
  height48,
  delIcon,
  pos,
} from './profile.variable';
import {Input} from 'antd-mobile';
// import globalStore from '@/services/global.state';
const ModelDemo = (props: any, ref: any) => {
  const [name, seTName] = useState('');
  const [errorMessage, setEerrorMessage] = useState('');
  useEffect(() => {
    if (!name) {
      setEerrorMessage('');
    }
  }, [name]);
  const handleupdateProfile = async () => {
    if (!name.trim()) {
      setEerrorMessage('Please enter a valid name');
      return;
    }
    if (['user', 'username'].includes(name.trim().toLowerCase())) {
      setEerrorMessage('This name is not allowed');
      return;
    }
    await updateProfile(name.trim(), '');
    const res = await props.getUserInfo();
    globalStore.userInfo = res;
    hide();
    setEerrorMessage('');
  };
  const {renderModal, show, hide} = useModal(
    <View style={[w295, modalStyle.container]}>
      <View style={[flex.flex, flex.alignEnd]}>
        <NativeTouchableOpacity
          onPress={() => {
            setEerrorMessage('');
            hide();
          }}>
          <LazyImage
            occupancy={'transparent'}
            imageUrl={del}
            width={w24}
            height={h24}
          />
        </NativeTouchableOpacity>
      </View>
      <View style={[flex.centerByCol]}>
        <Text style={[fontSize18, {fontWeight: 'bold', color: '#fff'}]}>
          USER NAME
        </Text>
      </View>
      <View style={[position.rel]}>
        <Input
          className={cls.input}
          style={{fontWeight: name.trim().length > 1 ? 'bold' : 'normal'}}
          placeholder={'please enter'}
          value={name}
          onChange={text => {
            name.trim().length >= 4 && setEerrorMessage('');
            if (/^[^\u4e00-\u9fa5]+$/.test(text)) {
              seTName(text);
            } else {
              seTName('');
            }
          }}
          maxLength={16}
        />
        <View style={[position.abs, pos, flex.flex, flex.center]}>
          <NativeTouchableOpacity
            onPress={() => seTName('')}
            style={[flex.flex1, flex.centerByRow, padding.lrl]}>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={delIcon}
              width={14}
              height={14}
            />
          </NativeTouchableOpacity>
        </View>
      </View>
      {errorMessage ? (
        <View style={[{marginBottom: 16, paddingLeft: 10, paddingRight: 10}]}>
          <Text style={[{color: '#ff0000'}]}> {errorMessage}</Text>
        </View>
      ) : null}
      <NativeTouchableOpacity
        onPress={() => {
          if (name.trim().length < 4) {
            setEerrorMessage(
              'Username Should Be Between 4 And 16 Characters Long. Please Retype.',
            );
          } else {
            handleupdateProfile();
          }
        }}>
        <View
          style={[
            height48,
            {
              backgroundColor: basicColor.primary,
            },
            flex.flex,
            flex.center,
            flex.centerByCol,
            borderRadius.l,
          ]}>
          <Text style={[fontSize18, {color: '#fff'}, font.fm, font.bold]}>
            SAVE
          </Text>
        </View>
      </NativeTouchableOpacity>
    </View>,
    {
      backDropClose: true,
      overlayStyle: {borderRadius: 20, backgroundColor: 'var(--card)'},
    },
  );
  useImperativeHandle(ref, () => ({
    show,
    handleSetName: (str: string) => seTName(str),
  }));
  return <View>{renderModal}</View>;
};

const modalStyle = StyleSheet.create({
  container: {},
});
export default forwardRef(ModelDemo);
